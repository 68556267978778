[data-reach-menu-item] {
  cursor: pointer;
  margin-bottom: 4px;
  margin-right: 10px;
  margin-left: 10px;
  border-radius: 0.2rem;
}

[data-reach-menu-list],
[data-reach-menu-items] {
  padding: 0.7rem 0 0.8rem 0 !important;
}

[data-reach-menu-item][data-selected] {
  background: #565c65 !important;
}

[data-reach-menu-link] {
  text-decoration: none;
  color: black;
  display: block;
}

[data-reach-menu-list] > *:last-child {
  margin-bottom: 0px;
  min-width: 120px;
}

[data-reach-menu-item][aria-disabled] {
  cursor: default !important;
}

[data-reach-menu-list] {
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  padding: 15px;
}

[data-reach-menu-list][tabindex]:focus {
  outline: none;
}

[data-reach-popover][data-reach-menu][data-reach-menu-popover] {
  z-index: 999999;
}
