.usa-combo-box__list-option[data-value="#005EA2"]::after {
  background-color: #005ea2;
  color: #005ea2;
  margin: 5px;
  border-radius: 4px;
  content: "pla";
}

.usa-combo-box__list-option[data-value="#54278f"]::after {
  background-color: #54278f;
  color: #54278f;
  margin: 5px;
  border-radius: 4px;
  content: "pla";
}

.usa-combo-box__list-option[data-value="#c05600"]::after {
  background-color: #c05600;
  color: #c05600;
  margin: 5px;
  border-radius: 4px;
  content: "pla";
}

.usa-combo-box__list-option[data-value="#002d3f"]::after {
  background-color: #002d3f;
  color: #002d3f;
  margin: 5px;
  border-radius: 4px;
  content: "pla";
}

.usa-combo-box__list-option[data-value="#136C66"]::after {
  background-color: #136c66;
  color: #136c66;
  margin: 5px;
  border-radius: 4px;
  content: "pla";
}

.usa-combo-box__list-option[data-value="#996600"]::after {
  background-color: #996600;
  color: #996600;
  margin: 5px;
  border-radius: 4px;
  content: "pla";
}

.usa-combo-box__list-option[data-value="#DC0061"]::after {
  background-color: #dc0061;
  color: #dc0061;
  margin: 5px;
  border-radius: 4px;
  content: "pla";
}

.usa-combo-box__list-option[data-value="#3e4ded"]::after {
  background-color: #3e4ded;
  color: #3e4ded;
  margin: 5px;
  border-radius: 4px;
  content: "pla";
}

.usa-combo-box__list-option[data-value="#008817"]::after {
  background-color: #008817;
  color: #008817;
  margin: 5px;
  border-radius: 4px;
  content: "pla";
}

.usa-combo-box__list-option[data-value="#5c1111"]::after {
  background-color: #5c1111;
  color: #5c1111;
  margin: 5px;
  border-radius: 4px;
  content: "pla";
}

.usa-combo-box__list-option[data-value="#e52207"]::after {
  background-color: #e52207;
  color: #e52207;
  margin: 5px;
  border-radius: 4px;
  content: "pla";
}

.usa-combo-box__list-option[data-value="#ab2165"]::after {
  background-color: #ab2165;
  color: #ab2165;
  margin: 5px;
  border-radius: 4px;
  content: "pla";
}

.usa-combo-box__list-option[data-value="#0f6460"]::after {
  background-color: #0f6460;
  color: #0f6460;
  margin: 5px;
  border-radius: 4px;
  content: "pla";
}
