textarea {
  resize: none !important;
  margin-top: 1rem !important;
}

.markdown-box {
  border: 5px solid rgb(50, 140, 252);
  border-radius: 2px;
  margin-top: 1rem;
  font-weight: 400;
  overflow: auto;
}

.markdown-box p {
  margin: 5px;
}
