@import "./styles/base";

p {
  line-height: 1.4;
}

.logo {
  margin-right: 0.5rem;
  width: 2rem;
  height: 2rem;
}

@media (min-width: 64em) {
  .navbar-long {
    width: 50% !important;
  }
}

.width-50 {
  width: 50% !important;
}

.preview-container {
  overflow: auto;
}

.line-chart > .recharts-legend-wrapper,
.column-chart > .recharts-legend-wrapper {
  top: -24px !important;
  left: 0 !important;
}

.bar-chart > .recharts-legend-wrapper {
  top: -28px !important;
  left: 0 !important;
}

.part-to-whole-chart > .recharts-legend-wrapper {
  top: -20px !important;
}

.overflow-x-hidden:hover {
  overflow-x: auto;
}

.check-data-table {
  overflow-x: hidden;
  overflow-y: hidden;
}

.check-data-table:hover {
  overflow-x: visible;
}

.scroll-shadow {
  box-shadow: inset -25px 0px 8px -20px rgba(224, 224, 224, 0.5);
}

.sticky-preview {
  position: -webkit-sticky;
  position: sticky;
  top: 90px;
}

.z-index-logout {
  z-index: 100000;
}

.recharts-default-legend {
  text-align: left !important;
}

.part-to-whole-chart > * .recharts-default-legend {
  margin-left: 8px !important;
}

.recharts-legend-item {
  margin-right: 16px !important;
}

.usa-header--basic .usa-nav__primary-item > .usa-current::after,
.usa-header--basic .usa-nav__link:hover::after {
  @include u-bg("base", "!important");
}

.usa-header--basic .usa-nav__primary-item > .usa-current::after,
.usa-header--basic .usa-nav__link:hover::after {
  @include u-bg("base", "!important");
}

@media all and (min-width: 64em) {
  .usa-nav__primary > .usa-nav__primary-item > a:hover {
    color: color("base", "!important");
  }
}

.usa-nav__primary button:hover {
  color: color("base", "!important");
}

@media all and (min-width: 64em) {
  .usa-nav__primary button[aria-expanded="true"] {
    @include u-bg("base", "!important");
    color: white !important;
  }
}

@media all and (min-width: 64em) {
  .usa-nav__submenu {
    @include u-bg("base", "!important");
  }
}

.usa-table tr:hover {
  @include u-bg("base-lighter", "!important");
}

.usa-table td,
.usa-table th {
  background-color: inherit;
}

.usa-table td:hover,
.usa-table th:hover {
  background-color: inherit;
}

.__react_component_tooltip.show {
  opacity: 1 !important;
}

@media (max-width: 64em) {
  .mobile-margin-top-2 {
    margin-top: 1rem;
  }
}

@media (min-width: 64em) {
  .usa-logo {
    margin-top: 0.7rem;
    margin-bottom: 0.7rem;
  }
}

@media (min-width: 64em) {
  .grid-container {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.tabs-horizontally::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.tabs-horizontally {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.padding-x-2-important {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.padding-left-105-important {
  padding-left: 12px !important;
}

.type-dark {
  @include u-bg("base-darkest", "!important");
}

@media (min-width: 64em) {
  .usa-nav-container {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.usa-table {
  margin: 0.75rem 0 !important;
}

.button-cell-padding {
  padding: 0 !important;
}

.button-cell-border {
  border-bottom: 0 !important;
}
